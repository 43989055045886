import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';

import useAuthStore from 'store/useAuth';
import React from 'react';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import { navigate } from 'gatsby';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';

import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';
import {
  unstable_useFormState as useFormState,
  unstable_Form as ReakitForm,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';

import InputField from '@wienenergiegithub/ui-next/src/common/components/InputField/InputField';

const createLaravelFormError = error => {
  const errors = {};
  if (!error?.response?.data) {
    throw errors;
  }
  Object.entries(error.response.data.errors).forEach(([key, val]) => {
    errors[key] = val.join(' ');
  }, {});
  throw errors;
};

const IndexPage = () => {
  const setNewPassword = useAuthStore(state => state.setNewPassword);

  const form = useFormState({
    values: {},
    onSubmit: async ({ email, password }) => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      try {
        await setNewPassword(email, password, token);
        navigate('/login');
      } catch (e) {
        createLaravelFormError(e);
      }
    },
  });
  return (
    <Wrapper>
      <Layout guestAllowed title="Paswort zurücksetzen">
        <Container size="sm">
          <br />
          <br />
          <Card>
            <ReakitForm {...form}>
              <InputField name="email" label="E-Mail" {...form} />
              <InputField
                name="password"
                type="password"
                label="Neues Passwort"
                {...form}
              />
              <br />
              <FormSubmitButton as={Button} {...form}>
                Passwort neu setzen
              </FormSubmitButton>
            </ReakitForm>
          </Card>
        </Container>
      </Layout>
    </Wrapper>
  );
};

export default IndexPage;
